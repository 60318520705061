  <template>
    <div class="calendarArea fill-height">
      <v-row class="fill-height" justify="center">
        <v-col cols="12" lg="10" class="fill-height">
          <div class="fill-height">
            <v-dialog width="500" v-model="addDialog">
              <v-card>
                <v-card-title>
                  افزودن
                </v-card-title>
                <v-card-text>
                  <v-expansion-panels class="pa-0 ec">
                    <v-expansion-panel class="pa-0">
                      <v-expansion-panel-header>
                        افزودن تکی
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0">
                        <div class="d-flex mt-3">
                          <div>
                            <v-text-field v-model="time.start_at" dense label="ساعت شروع"></v-text-field>
                          </div>
                          <div>
                            <v-select style="width: 100px" v-model="time.duration" label="مدت جلسه" item-text="title"
                                      item-value="key" dense
                                      :items="durations">

                            </v-select>
                          </div>
                          <v-spacer></v-spacer>
                          <div>
                            <v-btn x-small color="primary" elevation="0" @click="addTime(selectingDay)">
                              افزودن
                            </v-btn>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="pa-0">
                      <v-expansion-panel-header>
                        افزودن گروهی
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0">
                        <div>
                          <div class="d-flex mt-3">
                            <div>
                              <v-text-field v-model="bulkTime.start_at" dense label="ساعت شروع"></v-text-field>
                            </div>
                            <div>
                              <v-text-field v-model="bulkTime.end_at" dense label="ساعت پایان"></v-text-field>
                            </div>
                            <div>
                              <v-select style="width: 100px" v-model="bulkTime.rest_duration" label="زمان استراحت"
                                        item-text="title"
                                        item-value="key" dense
                                        :items="restDuration">

                              </v-select>
                            </div>
                            <v-spacer></v-spacer>
                          </div>
                          <div class="d-flex">
                            <v-chip small v-for="(duration , i) in durations" :key="i"
                                    @click="bulkTime.duration = duration.key"
                                    :color="bulkTime.duration == duration.key && 'primary'">
                              {{ duration.title }}
                            </v-chip>
                          </div>
                          <v-btn x-small color="primary" elevation="0" class="mt-5" @click="addBulkTimes(selectingDay)">
                            افزودن
                          </v-btn>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-dialog>
            <div class="d-flex fill-height flex-column justify-center">
              <div class="calenderBox">
                <div class="d-flex">
                  <v-btn small @click="toToToday" class="mr-2 font-weight-bold" text color="primary">
                    بازگشت به امروز
                  </v-btn>
                  <v-spacer></v-spacer>
                  <div>
                    <div>
                      <v-btn small @click="nextWeek" text>
                        <img src="../assets/icons/next.png" width="15px" alt="">
                      </v-btn>
                      <v-chip class="mx-2">
                        <span :key="i" v-for="(month , i) in monthGorgian">
                          <span class="mx-1" v-if="i == 1">-</span>
                          {{ month }}
                        </span>
                      </v-chip>
                      <v-chip>
                        <span :key="i" v-for="(month , i) in monthJalali">
                           <span class="mx-1" v-if="i == 1">-</span>
                           {{ $t(`months.${month}`) }}
                        </span>
                      </v-chip>
                      <v-btn small @click="pervWeek" text>
                        <img src="../assets/icons/next.png" width="15px" style="transform: rotate(-180deg)" alt="">
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="d-flex justify-space-between">
                    <div v-for="(day , i ) in weekDays" :key="i" class="day-row flex-grow-1"
                         :class="{today:isToday(day)}">
                      <div class="labelArea ">
                        <div class="d-flex justify-space-between">
                          <div>
                            {{ weeksDayLabel[i] }}
                          </div>
                          <div class="dates">
                            {{ day | dateGorgianFilter }}
                            <br>
                            {{ day | dateJalaliFilter }}
                          </div>
                        </div>
                      </div>
                      <div class="timeArea" @click="openModalAddTime(day)">
                        <div v-for="(time , i ) in getTimesForDay(day)" :key="i"
                             class="d-flex flex-column justify-center"
                             :class="time.editing ? 'editableTime' : 'timeBox'">
                          <div>
                            {{ time.end }} - {{ time.start }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </template>

  <script>
  /*eslint-disable*/
  import moment from 'moment';

  import momentJalali from "moment-jalaali";

  // import moment as momentG from "moment";

  export default {
    data() {
      return {
        weekDays: [],
        weeksDayLabel: [
          'شنبه',
          'یک‌شنبه',
          'دو‌شنبه',
          'سه‌شنبه',
          'چهارشنبه',
          'پنج‌شنبه',
          'جمعه'
        ],
        monthGorgian: [],
        monthJalali: [],
        addDialog: false,
        time: {
          start_at: null,
          duration: null,
        },
        bulkTime: {
          start_at: null,
          duration: null,
          end_at: null,
          rest_duration: null,
        },
        selectingDay: null,
        durations: [
          {
            key: "15",
            title: "15دقیقه"
          },
          {
            key: "30",
            title: "30دقیقه"
          },
          {
            key: "45",
            title: "45دقیقه"
          },
          {
            key: "60",
            title: "60دقیقه"
          },
          {
            key: "90",
            title: "90دقیقه"
          }
        ],
        restDuration: [
          {
            key: "5",
            title: "5دقیقه"
          },
          {
            key: "10",
            title: "10دقیقه"
          },
          {
            key: "20",
            title: "20دقیقه"
          },
          {
            key: "30",
            title: "30دقیقه"
          },
          {
            key: "40",
            title: "40دقیقه"
          }
        ],
        times: [
          {
            date: "2023-06-08",
            period: [
              {
                start: "10:00",
                end: "10:30"
              }
            ]
          },
          {
            date: "2023-06-05",
            period: [
              {
                start: "10:00",
                end: "10:30"
              }
            ]
          },
          {
            date: "2023-06-11",
            period: [
              {
                start: "10:00",
                end: "10:30"
              }
            ]
          }
        ],
        nextWeekCounter: 0,
        prevWeekCounter: 0,
        defaultMoment: moment().clone().startOf('week').subtract(1, 'day'),
      }
    },
    methods: {
      addBulkTimes(day) {
        this.insertDayIndexToTimes(day)
        let start = moment(this.bulkTime.start_at, 'HH:mm')
        let end = moment(this.bulkTime.end_at, 'HH:mm');
        while (start.isBefore(end)) {
          this.attachTimeToDay(day, start.format("HH:mm"), this.bulkTime.duration)
          start = start.add(this.bulkTime.duration, 'minutes').add(this.bulkTime.rest_duration, "minutes")
        }
        this.bulkTime = {
          start_at: null,
          duration: null,
          end_at: null,
          rest_duration: null,
        }
        this.addDialog = false
      },
      toToToday() {
        this.defaultMoment = moment().clone().startOf('week').subtract(1, 'day')
        this.prepareDateWeek(this.defaultMoment)
      },
      openModalAddTime(day) {
        this.selectingDay = day
        this.addDialog = true
      },
      insertDayIndexToTimes(day) {
        let date = moment(day).format('YYYY-MM-DD');
        if (this.times.findIndex(item => item.date == date) == -1) {
          this.times.push({
            date: date,
            period: []
          },)
        }
      },
      addTime(day) {
        this.insertDayIndexToTimes(day)
        this.attachTimeToDay(day, this.time.start_at, this.time.duration)
        this.time = {
          start_at: null,
          duration: null,
        }
        this.addDialog = false
      },
      attachTimeToDay(day, start, duration) {
        let date = moment(day).format('YYYY-MM-DD');
        let index = this.times.findIndex(item => item.date == date)
        this.times[index].period.push({
          start: start,
          end: this.prepareTimeByDuration(start, duration),
          editing: true,
        })
      },
      prepareTimeByDuration(time, duration) {
        const t = moment(time, 'HH:mm');
        t.add(duration, 'minutes');
        return t.format('HH:mm');
      },
      isToday(date) {
        return moment(date).format("YYYY-MM-DD") == moment(new Date()).format("YYYY-MM-DD")
      },
      prepareDateWeek(moment) {
        this.weekDays = []
        const startOfWeek = moment
        for (let i = 0; i < 7; i++) {
          const day = startOfWeek.clone().add(i, 'day');
          this.weekDays.push(day);
        }
        this.prepareMonthLabel()
      },
      prepareMonthLabel() {
        this.monthJalali = []
        this.monthGorgian = []
        this.weekDays.map(day => {
          if (this.monthGorgian.findIndex(item => item == day.format('MMMM')) == -1) {
            this.monthGorgian.push(day.format('MMMM'))
          }
          if (this.monthJalali.findIndex(item => item == momentJalali(day).format("jMMMM")) == -1) {
            this.monthJalali.push(momentJalali(day).format("jMMMM"))
          }
        });
      },
      // prepareMount

      nextWeek() {
        this.defaultMoment.add(1, 'week')
        this.prepareDateWeek(this.defaultMoment)
      },
      pervWeek() {
        this.defaultMoment.subtract(1, 'week')
        this.prepareDateWeek(this.defaultMoment)
      },
      getTimesForDay(day) {
        let date = moment(day).format('YYYY-MM-DD');
        let times = []
        this.times.map(item => {
          if (item.date == date) {
            times = item.period
          }
        })
        return times
      }
    },
    mounted() {
      this.prepareDateWeek(this.defaultMoment)
    },
    filters: {
      dateGorgianFilter(date) {
        return moment(date).format('YYYY/MM/DD')
      },
      dateJalaliFilter(date) {
        return momentJalali(date).format('jYYYY/jMM/jDD')
      }
    }
  }
  </script>

  <style scoped>
  .dates {
    font-size: 10px;
    text-align: left;
    color: #8290ac;
    font-weight: bolder;
  }

  .calenderBox {
    height: 80%;
  }

  .calendarArea {
    background: #e0e9f1;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .day-row {
    padding: 5px;
    background: white;
    margin: 5px;
    border: 1px solid #f6f7f9;
    border-radius: 10px;
  }

  .labelArea {
    padding: 5px;
    width: 100%;
  }

  .timeArea {
    width: 100%;
    height: 450px;
    overflow-y: auto;
    margin-top: 20px;

    /*background: #ddd;*/
    border-radius: 0px 0px 10px 10px;
    padding: 5px;

  }

  .today {
    border: 1px solid #55d3d2;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /*border-radius: 20px;*/
  }

  .timeBox {
    background: #f6f7fa;
    border-left: 6px solid #55d3d2;
    height: 40px;
    color: #1e263f;
    font-size: 10px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 0px 5px 5px 0px;
  }

  .editableTime {
    background: #f6f7fa;
    border-left: 6px solid #ffa935;
    height: 40px;
    color: #1e263f;
    font-size: 10px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 0px 5px 5px 0px;
  }
  </style>